.text-color {
    color: #911109;
}

.text-margin-bottom-label {
    margin-bottom: 13px;
}

.pagination div > div {
    flex: none;
}

.container-no-padding {
    padding-left: 0 !important;
}

.container-margin {
    margin-top: 45px;
}

.container-margin .MuiStack-root {
    margin-bottom: 20px;
}

.row-text-color {
    color: #000000;
}

.datePickerContainer {
    background-color: white;
}

.datePickerContainer .MuiPickersBasePicker-pickerView {
    background-color: white; /* Override the DatePicker background */
    border: none; /* Remove border */
  }
  

.datePickerContainer .MuiInputBase-root {
    padding: 0;
    border: 0;
    border-radius: 0;
}

.datePickerContainer .MuiInputBase-root input {
    padding: 7px 5px;
}

.datePickerContainer .css-93rqj-MuiInputAdornment-root {
    margin-right: 10px;
}

.datePickerContainer .MuiOutlinedInput-notchedOutline {
    border: none;
}

 .custom-select .MuiSvgIcon-root {
    display: none;
 }

 .disabled {
    color: #999999; 
    pointer-events: none;
  }

  .canvas-container {
    position: absolute;
    width: 0;
    height: 0;
    z-index: -1;
  }