.rmdp-week .rmdp-week-day {
    color: #911109;
    font-weight: 600;
}

.rmdp-week .rmdp-day.rmdp-today span{
    background: none;
    color: #000000;
}

.rmdp-week .rmdp-range {
    background-color: #f1f6ff;
    color: #7D86A9;
    border: none;
    box-shadow: none;
}

.rmdp-day.rmdp-range-hover {
    background-color: #f1f6ff;
    color: #7D86A9;
}

.rmdp-range.start {
    background: rgb(22,118,255);
    background: linear-gradient(90deg, rgba(22,118,255,1) 90%, rgba(241,247,255,1) 100%);
}

.rmdp-range.end {
    background: rgb(241,247,255);
    background: linear-gradient(90deg, rgba(241,247,255,1) 0%, rgba(22,118,255,1) 10%);
}

.rmdp-range.start, .rmdp-range.end {
    background-color: #1676ff;
    color: white;
}

.rmdp-container .rmdp-input {
    border-radius: 0;
    height: 36px;
    margin: 1px 0;
    padding: 2px 5px;
    border: none;
    width: 100%;
    min-width: 300px;
    font: inherit;
    color: currentColor;
    padding-left: 10px;
}

.rmdp-container .rmdp-input:focus {
    -webkit-box-shadow: 11px 11px 25px -11px rgba(0,0,0,0.13);
    -moz-box-shadow: 11px 11px 25px -11px rgba(0,0,0,0.13);
    box-shadow: 11px 11px 25px -11px rgba(0,0,0,0.13);
    border: none;
}